<template>
  <v-card class="my-4">
    <v-card-title><a class="cw-link" href="">{{title}}</a></v-card-title>
    <v-card-text>
      aaaaaaaaaaaaaaaaaaaaaaaaaa啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊aaaaaaaaaaaaaaaaaaaaaaaaaaaa啊啊啊aaaaaaaaaaaaaaaaa啊
      CODE：
      <code>
        function aaa{}
      </code>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SearchResultBox",
  props: {
    title: {
      type: String,
      default: 'TITLE'
    },
  },
};
</script>

<style lang="scss" scoped>

.cw-link{
  text-decoration: unset;
}
.cw-link:hover{

}
</style>