<template>
  <div class="cw-search-view">
    <v-container class="mt-n4">
      <span class="grey--text text-subtitle-2">在{{getTimeline}}中查询到共 100 条结果</span>
      <search-result-box v-for="item in 20" :key="'srb@'+item" :title="'结果'+item"></search-result-box>
    </v-container>
  </div>
</template>

<script>
import searchBox from "../components/SearchBox.vue";
import SearchResultBox from '../components/SearchResultBox.vue';
export default {
  name: "Search",
  data() {
    return {
      searchFlag: false,
    }
  },
  components: {
    searchBox,
    SearchResultBox,
  },
  computed: {
    getTimeline() {
      let timeText = {
        0: "所有时间",
        1: "过去一个月",
        6: "过去六个月",
        12: "过去一年",
      }
      return timeText[this.$store.state.SEARCH.timeline]
    }
  },
  mounted() {
    this.$store.state.APP_BAR.showBg = false;
    this.$store.state.APP_BAR.showSearch = true;
  },
};
</script>

<style lang="scss" scoped>
.cw-search-view {
  padding-top: .7rem;
}
</style>